<template>

  <transition-group name="fade">

    <component
      :is="component.template"
      v-for="component of parts"
      :id="component.slug"
      :key="component.id"
      :payload="component"
      class="fade-item"
    >
      <h2>Component «{{ component.template }}» not registered.</h2>
    </component>

    <Footer key="footer" class="fade-item" />
  </transition-group>

</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {

    NotFound: defineAsyncComponent(() => import('@/components/structure/NotFound.vue')),
    Footer: defineAsyncComponent(() => import('@/components/structure/Footer.vue')),

    Page: defineAsyncComponent(() => import('@/components/structure/Page.vue')),
    InfoPage: defineAsyncComponent(() => import('@/components/structure/InfoPage.vue')),
    Job: defineAsyncComponent(() => import('@/components/structure/Job.vue')),

  },
  props: { inject: { type: Object, default: Object } },
  computed: {
    parts() {
      return this.inject.filter((part) => !part?.hidden);
    },
  },
};
</script>
