<template>
  <Go class="Btn" :to="link" :class="{noHover: isMobileDevice}">
    <slot />
  </Go>
</template>

<script>
import Go from '../utils/Go.vue';

export default {
  components: {
    Go,
  },
  props: {
    to: String,
  },
  data() {
    return {};
  },
  computed: {
    isMobileDevice() {
      // eslint-disable-next-line max-len
      return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent);
    },
    link() {
      return this.to?.startsWith('PAGE_') ? `/${this.$store.state.router.locale}/${this.to.substring(5)}` : this.to;
    },
  },
};
</script>

<style lang="scss" scoped>
.Btn {
  border: unset;
  @apply bg-yellow text-black border rounded-3xl font-mono text-base pt-2.25 pb-3 px-5 cursor-pointer shadow-sm;
  @include smLess {
    @apply text-sm pt-1.5 pb-2 px-3;
  }

  &:is(:hover, :focus) {
    &:not(.noHover) {
      @apply bg-yellow-dark shadow-md;
    }
  }

  &.active {
    @apply bg-brown text-white;
  }
}
</style>
