<template>
  <div class="hamburger">
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.hamburger {
  @apply flex flex-col justify-center items-center h-full w-full gap-0.5;
  transition: background-color .25s ease-in-out;

  .hover {
    .bar {
       @apply bg-yellow;
    }
  }
}

.bar {
  height: 5px;
  background-color: currentColor;
  border-radius: 3px;
  transition: all 0.35s;

  &:nth-child(1) {
    @apply w-full mb-1;

    .active & {
      transform: translateY(calc(225%)) rotate(45deg);
    }
  }

  &:nth-child(2) {
    @apply w-full mb-1;
    .active & {
      transform: translateX(500%);
      opacity: 0;
    }
  }

  &:nth-child(3) {
    @apply w-full;
    .active & {
      transform: translateY(calc(-225%)) rotate(-45deg);
    }
  }

}
</style>
