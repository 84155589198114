<template>
  <div id="nav" class="Nav constrain" :class="{'hide-bg': hamburgerOpened, 'shadow-md': headerShadow}">

    <nav>
      <div
        ref="hamburgerBtn"
        class="hamburger"
        @mouseover="hamburgerHoverOn"
        @focusin="hamburgerHoverOn"
        @mouseleave="hamburgerHoverOff"
        @focusout="hamburgerHoverOff"
        v-on="hamburgerOpened ? {click: hamburgerClose} : {click: hamburgerOpen}"
      >
        <Hamburger :class="{active: hamburgerOpened, hover: hamburgerHovered}" />
      </div>
      <Button :to="footer?.ctaLink" class="topBtn">
        Contact
      </Button>
    </nav>

    <Go :to="'/' + $store.state.router.locale" aria-label="Home" class="logo">
      <Logo />
    </Go>

  </div>
  <div ref="navMenu" class="nav-links" :class="{opened: hamburgerOpened}">
    <div ref="navMenuMain" class="links--main">
      <div class="link">
        <a
          aria-label="Home"
          @mouseenter="mainLinkHoverOn(-1)"
          @focusin="mainLinkHoverOn(-1)"
          @click="(e) => navigateAway(e, '/' + $store.state.router.locale)"
        >
          Home
        </a>
      </div>
      <a
        v-for="(link, index) in navigation?.links"
        :key="link.slug"
        class="link"
        @click="(e) => (link.children.length && isMobileDevice) ? navigateAwayAfterHover(e, link.uri) : navigateAway(e, link.uri)"
        @mouseenter="mainLinkHoverOn(index)"
        @focusin="mainLinkHoverOn(index)"
        @mouseleave="mainLinkHoverOn(index, true)"
        @focusout="mainLinkHoverOn(index, true)"
      >
        <span>{{ link.title }}</span>
        <ArrowIcon v-if="link?.children.length" />
      </a>
    </div>
    <div ref="navMenuSecondary" class="links--secondary" :class="{showSecondary: secondaryLinkData.length}">
      <a
        v-for="link in secondaryLinkData"
        :key="link.slug"
        class="link"
        @click="(e) => navigateAway(e, link.uri)"
      >
        {{ link.title }}
      </a>
    </div>
  </div>
</template>

<script>

import Scrolllock from '@/utils/scrolllock';
import Hamburger from '../utils/Hamburger.vue';
import Button from '../UI/Button.vue';
import Go from '../utils/Go.vue';

import Logo from '../../assets/images/logo.svg';
import ArrowIcon from '../../assets/images/icon-arrow.svg';

export default {
  components: {
    Hamburger,
    Logo,
    Button,
    Go,
    ArrowIcon,
  },
  setup() {
    return {
      lock: Scrolllock.lock,
      unlock: Scrolllock.unlock,
    };
  },
  data() {
    return {
      hamburgerHovered: false,
      secondaryLinkData: [],
      headerShadow: false,
      currentLink: undefined,
      currentHover: undefined,
    };
  },
  computed: {
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
    hamburgerOpened() {
      return this.$store.state.navigation.hamburgerOpen;
    },
    isMobileDevice() {
      // eslint-disable-next-line max-len
      return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent);
    },
    footer() {
      return this.$store.state.router.layout.footer;
    },
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.secondaryLinkData = [];
        this.$store.commit('navigation/toggleHamburgerOff');
      }
    });

    window.addEventListener('click', (e) => {
      setTimeout(() => {
        if (this.hamburgerOpened) {
          const mainBound = this.$refs.navMenuMain?.getBoundingClientRect().right;
          const allBound = this.$refs.navMenu?.getBoundingClientRect().right;

          if (this.secondaryLinkData && e.clientX > allBound) {
            this.secondaryLinkData = [];
            this.$store.commit('navigation/toggleHamburgerOff');
          } else if (e.clientX > mainBound) {
            this.secondaryLinkData = [];
            this.$store.commit('navigation/toggleHamburgerOff');
          }
        }
      }, 200);
    });
  },
  methods: {
    hamburgerHoverOn() {
      this.hamburgerHovered = true;
    },
    hamburgerHoverOff() {
      this.hamburgerHovered = false;
    },
    hamburgerOpen() {
      this.secondaryLinkData = false;
      this.$store.commit('navigation/toggleHamburgerOn');
    },
    hamburgerClose() {
      this.$store.commit('navigation/toggleHamburgerOff');
    },
    mainLinkHoverOn(index, cancelEvent) {
      const setLinkData = () => {
        this.secondaryLinkData = this.navigation.links[index].children;
      };
      const hoverIt = () => {
        let timeout;
        if (index >= 0 && this.navigation.links[index].children.length) {
          // if (this.currentHover !== index) {
          //   if (!cancelEvent) {
          //     timeout = setTimeout(setLinkData, 500);
          // this.currentHover = index;
          // }
          // } else {
          //   clearTimeout(timeout);
          //   this.currentHover = index;
          setLinkData();
          // }
        } else {
          this.currentHover = index;
          this.secondaryLinkData = [];
        }
      };
      hoverIt();
    },
    navigateAwayAfterHover(e, link) {
      if (!this.currentLink || this.currentLink !== link) {
        this.currentLink = link;
      } else {
        this.currentLink = undefined;
        this.navigateAway(e, link);
      }
    },
    navigateAway(e, link) {
      this.hamburgerClose();
      this.$emit('click', e);
      if (e.which === 2 || e.button === 4 || e.ctrlKey || e.metaKey) return;
      if (e.defaultPrevented) return;
      e.preventDefault();
      setTimeout(() => {
        this.$store.dispatch('router/push', { uri: link });
      }, 100);
    },
    debounce(callback, wait = 250) {
      let timeoutId = null;
      return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
          callback.apply(null, ...args);
        }, wait);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.Nav {
  @apply fixed top-0 w-full flex items-start justify-between bg-transparent text-blue pt-13 pb-16 leading-none z-50;
  transition: background-position 0.2s ease-in-out;
  background: linear-gradient(to bottom, var(--color-white-transparent-50) 0%, transparent 100%) no-repeat;
  max-width: 100vw;

  @apply pointer-events-none;
  >* { @apply pointer-events-auto; }

  @include mdLess { @apply pt-10; }
  @include smmdLess { @apply pt-8; }
  @include smLess {
    @apply pt-6 bg-white pb-4 shadow-md;
  }

  &.hide-bg {
    background-position: 0 -150px;
  }

  nav {
    @apply flex gap-6 items-center;
    @include smLess {
      @apply gap-4;
    }

    .hamburger {
      @apply cursor-pointer w-10 h-full;
      @include smLess {
        @apply w-8;
      }
    }
  }

  .logo {
    @apply w-48 h-full -translate-y-3;

    @include smLess {
      @apply w-36 -translate-y-1.5;
    }

    @include smLess {
      @apply w-28 translate-y-0;
    }

    &:is(:hover, :focus) {
      svg {
        :deep(path) {
          fill: var(--color-yellow) !important;
        }
      }
    }
  }

}

.nav-links {
  @apply fixed -left-full top-0 h-screen flex z-40 isolate;
  transition: all 0.25s ease-in-out;

  &.opened {
    @apply left-0;
  }

  .links--main {
    @apply min-w-[25vw] bg-white text-black text-xl flex flex-col gap-8 pt-46 pl-13 pr-6 relative z-20 overflow-scroll;
    @include lgLess { @apply text-lg; }
    @include mdLess { @apply text-md w-[50vw] pt-32; }
    @include smLess { @apply text-base pl-6 pr-2 pt-28; }
    @include hideScrollBar;

    .link {
      @apply cursor-pointer pr-6 relative;
      &:is(:hover, :focus) {
        @apply text-blue;
        svg {
          fill: var(--color-blue);
        }
      }
      svg {
        @apply absolute right-0 top-1/2 -translate-y-1/2 h-full w-8 -rotate-90;
        @include lgLess { @apply w-6; }
        @include mdLess { @apply w-5; }
        @include smLess { @apply w-4; }
      }
    }
  }

  .links--secondary {
    @apply min-w-[25vw] bg-blue text-white text-lg relative z-10 pt-46 pl-13 flex flex-col gap-8 overflow-y-scroll;
    @apply transition-transform ease-in-out delay-100;
    transform: translateX(calc(-100% - 1px));
    box-shadow: 2px 0 5px 5px var(--color-blue-transparent-10);
    @include lgLess { @apply text-md; }
    @include mdLess { @apply text-base w-[50vw] pt-32; }
    @include smLess { @apply pl-6 pr-2 pt-28; }
    @include hideScrollBar;

    &.showSecondary {
      transform: translateX(-1px);
    }

    .link {
        @apply block cursor-pointer;
        max-width: calc(25vw - 5rem);
        @include mdLess {
          max-width: calc(50vw - 5rem);
        }

        &:is(:hover, :focus) {
          @apply text-white-50;
        }
    }
  }
}
</style>
