const initialState = () => ({
  hamburgerOpen: false,
});

const mutations = {
  toggleHamburgerOn(state) {
    state.hamburgerOpen = true;
  },
  toggleHamburgerOff(state) {
    state.hamburgerOpen = false;
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
};
