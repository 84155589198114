import { createStore, createLogger } from 'vuex';
import router from './modules/router';
import navigation from './modules/navigation';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    router,
    navigation,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
